a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.floating-button {
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 1fr;
  position: fixed;
  top: 3vw;
  right: 5%;
  background-color: #ffffff;
  border-radius: 50px;
  width: 45%;
  height: 10vw;
  text-align: center;
  align-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  z-index: 100000;
}

.floating-button a {
  position: relative;
  color: #000000;
  font-size: 4vw;
  text-align: center;
  text-decoration: none;
  align-self: center;
}

.floating-button a:hover {
  color: #000000;
  text-decoration: none;
}

.phoneIcon {
  display: grid;
  width: 7vw;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-left: 30%;
  filter: invert();
}

@media screen and (min-width: 1001px) {
  .floating-button {
    width: 22vw;
    height: 3vw;
    right: 5%;
    top: 1.5vw;
  }

  .floating-button a {
    font-size: 1.3vw;
  }

  .phoneIcon {
    width: 2.5vw;
  }
}
