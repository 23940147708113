.services {
    text-align: center;
    margin-top: 2rem;
}

.services h2 {
    display: inline;
    font-family: 'Pontano Sans', sans-serif;
    font-size: 8vw;
}

.items-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    flex-direction: row;
    text-align: start;
    margin-bottom: 1rem;
}

.item img {
    margin-left: .5rem;
    margin-right: 1rem;
    min-width: 20vw;
}

.item-info {
    margin-right: .5rem;
    margin-left: .5rem;
}

.item-info h3 {
    font-size: 6vw;
    color: #00447E;
}

.item-info p {
    font-size: 4vw;
    color: #3C3C3C;
}

.service-footer p {
    padding: .5rem;
    font-size: 4vw;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.service-footer img {
    min-width: 20vw;
}


.service-footer button {
    display: block;
    filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, .25));
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 5vw;
    background-color: #fdca02;
    border-radius: 6px;
    border: 0;
    height: 8vh;
    width: 90%;
}

.service-footer button[type=button]:active {
    margin-top: 1.8rem;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
    margin-bottom: .7rem;
}


@media screen and (min-width: 1001px) {
    .services {
        margin-top: 0rem;
    }

    .services h2 {
        font-size: 4vw;
    }

    .items-container {
        flex-direction: row;
        justify-content: space-around;
    }


    .item {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 1rem;
        width: 90%;
    }

    .item img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        min-width: 10vw;
        margin-bottom: 3vw;
    }

    .item-info h3 {
        font-size: 2.5vw;
    }

    .item-info p {
        font-size: 1.5vw;
    }

    .service-footer p {
        padding: .5rem;
        font-size: 2vw;
        font-weight: 500;
    }

    .service-footer img {
        min-width: 10vw;
    }


    .service-footer button {
        font-size: 2.5vw;
        width: 50%;
        margin-bottom: 5rem;
    }

    .service-footer button[type=button]:active {
        margin-bottom: 4.7rem;
    }
}