input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

@keyframes leftToRight {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0);
  }
}

.message {
  position: absolute;
  width: 80%;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #cbcaca;
  width: 85vw;
  border-radius: 6px;
  padding: 2rem;
  margin-bottom: 10%;
  animation: leftToRight 1s ease;
  font-family: "Poppins", sans-serif;
  filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, 0.5));
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.form h2 {
  color: #064273;
  font-size: 3.6vw;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-align: left;
}

input {
  padding: 12px;
  height: 12vw;
  background-color: #ffffff;
  border-radius: 5px;
}

::placeholder {
  font-size: 4vw;
  font-weight: 600;
}

label {
  color: #3d3d3d;
  font-size: 4vw;
  font-weight: normal;
  margin-top: 0.5rem;
  font-weight: 500;
}

button[type="submit"] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 4vw;
  background-color: #fdca02;
  border-radius: 6px;
  border: 0;
  height: 8vh;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
}

button[type="submit"]:active {
  margin-top: 1.8rem;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 0.7rem;
}

.checkForm {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-template-rows: 1fr;
}

.conditions {
  display: grid;
  grid-column: 2/2;
  grid-row: 1/1;
  align-items: center;
  justify-self: center;
}

.checkbox-container {
  display: grid;
  grid-column: 1/1;
  grid-row: 1/1;
}

label.checkLabel {
  margin-top: 1rem;
  width: 100%;
}

input[type="checkbox"] {
  margin-left: 25%;
  width: 40%;
  margin-top: 40%;
}

.popup {
  width: 86vw;
  height: 30%;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 35%;
  overflow-y: scroll;
}

.popuptext {
  display: none;
}

.popuptext-show {
  display: flex;
}

@media screen and (min-width: 1001px) {
  .form {
    justify-self: normal;
    width: 60vw;
  }

  .form h2 {
    font-size: 1.1vw;
    text-align: center;
  }

  input,
  select {
    height: 2.8vw;
    font-size: 0.8vw;
  }

  ::placeholder {
    font-size: 0.9vw;
  }

  label {
    font-size: 1vw;
  }

  button[type="submit"] {
    font-size: 1.1vw;
    font-weight: 600;
    height: 6vh;
  }

  .popup {
    width: 93%;
    height: 50%;
    font-size: 1.2vw;
  }

  .conditions {
    justify-self: start;
  }

  label.checkLabel {
    margin-top: 10%;
    width: 100%;
  }

  input[type="checkbox"] {
    margin-left: 50%;
    margin-top: 10%;
    width: 40%;
  }
}
