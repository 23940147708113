input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16vw;
  font-family: "Roboto", "Poppins", sans-serif;
}

.fullPage .top-bar {
  background-color: #005da9;
  padding: 2.5vw;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
}

.slider-position {
  grid-column: 1/1;
  grid-row: 2/2;
}

.form-position {
  margin-top: 10vw;
  grid-column: 1;
  grid-row: 3/3;
  justify-self: center;
}

.security-position {
  grid-column: 1;
  grid-row: 4/4;
}

.services-position {
  grid-column: 1;
  grid-row: 5/5;
}

@media screen and (min-width: 1001px) {
  .background-brightness {
    position: fixed;
    z-index: 9999999;
    left: 20vw;
    top: 7vw;
  }

  .fullPage {
    grid-template-columns: 30% 70%;
    grid-template-rows: 6vw;
    background-color: #e5e5e5;
  }

  .brightness {
    filter: brightness(0.3);
  }

  .fullPage .top-bar {
    background-color: #005da9;
    padding: 1vw;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    /* height: 10%; */
    z-index: 99;
    overflow: hidden;
    grid-column: 1/2;
    grid-row: 1/1;
  }

  .slider-position {
    grid-column: 1/3;
    grid-row: 2/2;
  }

  .form-position {
    grid-column: 1/3;
    grid-row: 3/3;
    margin-top: 5vw;
    justify-self: center;
  }

  .security-position {
    grid-column: 1/3;
    grid-row: 4/4;
    background-color: #ffffff;
  }

  .services-position {
    margin-top: 3vw;
    grid-column: 1/3;
    grid-row: 5/5;
    background-color: #ffffff;
  }
}
