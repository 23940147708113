.slider-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 1fr;
  font-family: "Poppins";
}

.slider-title h2 {
  text-align: center;
  font-weight: 500;
  grid-column: 1/1;
  grid-row: 1/1;
}


.slider-products-title {
  margin-top: 4vw;
  font-size: 4.5vw;
}

.slider-services-title {
  margin-top: 4vw;
  font-size: 5vw;
}

.slider-promos-title {
  margin-top: 6vw;
  font-size: 5vw;
}

.slider-title h3 {
  text-align: center;
  font-size: 4vw;
  grid-column: 1/1;
  grid-row: 1/1;
  margin-top: 12vw;
}

.carousel {
  grid-column: 1/1;
  grid-row: 2/2;
  width: 100%;
  margin-top: -8vw;
}

@media screen and (min-width: 1001px) {
  .slider-title {
    display: flex;
    flex-direction: column;
  }

  .slider-title h2 {
    display: none;
  }

  .slider-title h3 {
    margin-top: 1vw;
    font-size: 2vw;
    font-weight: 500;
    max-width: max-content;
    align-self: center;
    font-family: "Roboto";
    color: #022e50;
  }

  .carousel {
    width: 100%;
    align-self: center;
    margin-top: 0.2vw;
  }
}
