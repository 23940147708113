.security {
  background-color: #fdca02;
  text-align: center;
  font-family: "Pontano Sans", sans-serif;
  padding: 6vw 4vw 10vw 4vw;
}

.security h2 {
  font-size: 6vw;
  margin-bottom: 10vw;
}

.security-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 5vw;
}
.security button {
  background-color: #c42a20;
  font-family: "Roboto";
  color: #ffffff;
  margin-top: 5vw;
  margin-bottom: 1vw;
  font-weight: 600;
  width: 70%;
  border: 0;
  border-radius: 50px;
  padding: 1vw;
  height: 10vw;
  font-size: 4.5vw;
  filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
}
.security button:active {
  margin-top: 5.3vw;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 0.7vw;
}

@media screen and (min-width: 450px) {
  .security-images img {
    width: 40%;
  }
}

@media screen and (min-width: 1001px) {
  .security {
    padding: 6vw 4vw 6vw 4vw;
  }

  .security h2 {
    font-size: 3vw;
  }

  .security-images img {
    width: 20vw;
  }
  .security button {
    font-size: 2vw;
    width: 30%;
    height: 5vw;
  }

  .security button:active {
    margin-top: 5.3vw;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  }
}
